* {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.notes {
  color: red;
  width: 50%;
  margin: 48px auto;
}

body {
  background-color: #000;
  color: #fff;
}

img {
  width: 100%;
}

p {
  font-family: titling-gothic-fb, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16pt;
  letter-spacing: .5px;
  line-height: 1.4;
  margin-bottom: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

blockquote {
  font-family: titling-gothic-fb, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  margin-top: 8px;
}

header {
  position: fixed;
  top: 0;
  width: 100vw;
  box-sizing: border-box;
  background: #000;
  padding: 24px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  animation-delay: 0.1s;
  transition: 0.5s;
}

#header img {
  transition: 0.3s;
  background-color: #000;
  transform-origin: bottom;
}

#header.smaller {
  transform: translateY(-50px);
  animation-delay: 0.1s;
  transition: 0.5s;
}

#header.smaller img {
  transform: scale(.5) translateY(15px);
  transition: 0.3s;
}

#header.smaller .header-link {
  transform: translateY(25px);
  transition: 0.3s;
}

.header-link {
  font-family: titling-gothic-fb, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block;
  position: relative;
  transition: 0.2s;
}

.header-link:hover {
  color: #000;
}

.header-link:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  background-color: palegreen;
  height: 22px;
  width: 0;
  transition: 0.2s;
  z-index: -1;
}

.header-link:hover:after {
  width: 100%;
}

section {
  padding: 240px 10% 48px;
  transition: 0.3s;
}

.home-intro {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 5%;
}

.home-intro h4 {
  font-size: 120pt;
  font-family: titling-gothic-fb-extended, sans-serif;
  font-weight: 500;
  margin-bottom: 48px;
}

.home-intro h2 {
  font-size: 36pt;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  margin-bottom: 24px;
}

.home-intro p {
  font-size: 24pt;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 500;
}

.logo {
  margin-right: auto;
}

.work-link {
  margin-right: 48px;
}

.logo img {
  height: 80px;
  width: auto;
}



/********************************    MENU STUFF    ********************************/

.menu-wrapper {
  margin-top: -100px;
  width: 100%;
  overflow: hidden;
}

.menu-item {
  padding: 0 20% 0 10%;
  margin-bottom: 128px;
  transition: 0.3s;
}

.menu-title {
  font-family: titling-gothic-fb-extended, sans-serif;
  font-size: 60pt;
  font-weight: 900;
  z-index: 10;
  transform-origin: left;
  transition: 0.3s;
  overflow: visible;
  white-space: nowrap;
}

.menu-image {
  width: 100%;
  transition: 0.3s;
}

.menu-item:hover .menu-image {
  transform: scale(1.2);
  /* z-index: 9; */
}

.menu-item:hover .menu-title {
  transform: scale(1.5);
  transform-origin: left;
  /* z-index: 10; */
}

/* #menu-true:hover .menu-title {
  transform: scale(1.0);
  font-size: 80pt;
  letter-spacing: 10px;
  z-index: 10;
}

#menu-calo:hover .menu-title {
  transform: scale(1.2);
  z-index: 10;
} */



.work-menu {
  margin-top: 200px;
}




/********************************    ABOUT STUFF    ********************************/

.about-page {
  margin-top: 200px;
  padding: 0 10%;
}

.derek-photo {
  max-width: 50%;
  float: right;
  margin: 0 0 32px 32px;
}

.about-page h1 {
  font-family: titling-gothic-fb-extended, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 36pt;
  margin-bottom: 32px;
}

.about-section {
  margin-bottom: 48px;
}

.about-page h2 {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24pt;
  margin-bottom: 16px;
}

.tools {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 16px;
}

/* .tools div:not(:last-child) {
  margin-right: 16px;
} */

.tool-list h4 {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-top: 8px;
}

.about-page ul {
  padding: 0;
  padding-left: 8px;
}

.about-page li {
  margin-bottom: 8px;
  list-style-type: none;
  font-family: titling-gothic-fb, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12pt;
  letter-spacing: .5px;
  line-height: 1.4;
}

@media only screen and (max-width: 1200px) {
  .tools {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 48px;
  }
}

@media only screen and (max-width: 800px) {
  .about-page {
    padding: 0 5%;
  }
}


/********************************    PROJECT STUFF    ********************************/

.project-intro {
  margin-top: 200px;
  margin-bottom: 0;
}

.project-info {
  padding: 0 10% 64px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-auto-columns: minmax(260px, 1fr);
  grid-gap: 64px;
  align-items: baseline;
  transition: 0.3s;
}

.project-title {
  font-family: titling-gothic-fb-extended, sans-serif;
  font-size: 32pt;
  margin-bottom: 24px;
}

.project-roles {
  margin-bottom: 48px;
}

.project-roles h5 {
  font-size: 14pt;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 8px;
}

.project-description {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18pt;
  line-height: 1.4;
}

.section-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 48px;
  align-items: baseline;
  /* margin-bottom: 64px; */
  transition: 0.3s;
}

.section-role {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16pt;
  margin-bottom: 10px;
}

.section-title {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28pt;
  margin-bottom: 16px;
}

.project-hero img {
  width: 100%;
}

.vertical-gallery > div {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(400px, 1fr) );
  grid-gap: 16px;
  margin-top: 48px;
  transition: 0.3s;
}

/* .vertical-gallery div {
  display: grid;
  grid-gap: 16px;
  transition: 0.3s;
} */

.vertical-gallery-block {
  width: 100%;
}

.vertical-gallery-block img {
  width: 100%;
}

.horizontal-gallery {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.horizontal-gallery-block {
  display: inline-block;
  width: 32.4%;
  margin-right: 16px;
}

.horizontal-gallery-block:last-child {
  margin-right: 0;
}

.horizontal-gallery-block img {
  width: 100%;
}

.iframe-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 48px ;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-wrapper iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.promo-video {
  padding-top: 48px;
}

.project-nav {
  padding: 10%;
  margin-top: 64px;
  background-color: #181818;
  text-align: center;
  transition: 0.3s;
}

.project-nav a:hover {
  color: palegreen;
}

.project-nav h5 {
  font-size: 12pt;
  font-family: titling-gothic-fb, sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 8px;
}

.project-nav-next {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-nav-next h3 {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 36pt;
}

.next-project {
    font-family: titling-gothic-fb-extended, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20pt;
}

.next-icon {
  font-size: 36pt;
  margin: 32px 0 16px;
}

.react-photo-gallery--gallery img {
  width: auto;
}

.react-photo-gallery--gallery {
  margin-top: 48px;
}

/* .full-width-image {
  padding: 48px 0;
} */


/********************************    CRACK STUFF    ********************************/

#crack-intro {
  margin-bottom: 0;
}

.start-from-scratch {
  padding: 96px 0 48px;
}

.start-from-scratch h5 {
  padding: 0 10%;
}

.start-from-scratch .section-info {
  padding: 0 10%;
}

.four-image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-top: 48px;
}

.fifty-fifty {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 48px;
  transition: 0.3s;
}

.col-2-1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  column-gap: 48px;
  transition: 0.3s;
}

.double-wide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 48px;
}

.prototype-link {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.prototype-link h5 {
  margin-bottom: 16px;
  font-family: titling-gothic-fb, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14pt;
  text-align: center;
  transition: 0.3s;
}

.prototype-link img {
  transition: 0.3s;
}

.prototype-link:hover img {
  transform: scale(1.1);
  transition: 0.3s;
}

.prototype-link:hover h5 {
  transform: translateY(-24px);
  transition: 0.3s;
}

/* .prototype-wrapper {
  position: relative;
  padding-bottom: 146.25%;
  height: 0;
} */

.prototype-wrapper img {
  width: 60%;
  justify-self: center;
}

/* .prototype-wrapper div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.prototype-wrapper h5 {
  margin-top: 16px;
  font-size: 14pt;
} */

.paper-image {
  width: 100%;
  grid-column-start: 1;
  grid-row-start: 1;
}

.paper-info {
  grid-column-start: 2;
}

.paper-image img {
  width: 100%;
}

.sub-section {
  margin-top: 120px;
}

.section-subhead {
  text-align: center;
  width: 70%;
  margin: 0 auto 48px;
}

.section-info-half {
  grid-template-columns: 1fr 1fr;
}

.section-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.first-things {
  display: grid;
  grid-template-columns: 1fr 2.284fr 1fr;
  grid-gap: 16px;
  margin-top: 48px;
}

.two-column-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-gap: 24px;
}

.iphone8-iframe-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 178%;
}

.iphone8-iframe-wrapper iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.two-video-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat( 2, 1fr );
  margin-top: 48px;
}

.three-video-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 48px;
}

.learnings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.learning h3 {
  font-family: titling-gothic-fb-extended, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 24pt;
  margin-bottom: 8px;
}

.callout {
  font-family: titling-gothic-fb-extended, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20pt;
  margin: 96px 0 48px;
}

.last-image {
  margin-bottom: 64px;
}

/* .three-column-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
} */

@media only screen and (max-width: 700px) {
  .two-column-grid {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }
  
  /* .three-column-grid {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  } */

}

@media only screen and (max-width: 600px) {
  .paper-prototype {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }

}

@media only screen and (max-width: 360px) {
  .paper-prototype {
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  }
  .two-column-grid {
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  }
  
  .three-column-grid {
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  }

}


/********************************    KFC STUFF    ********************************/

.kfc-hero-video {
  grid-column-start: 1;
  grid-column-end: -1;
}

.single-image-wrapper {
  margin-top: 48px;
}

.single-image-wrapper img {
  width: 100%;
}




/********************************    VIDEO STUFF    ********************************/

.video-gallery {
  padding: 240px 5%;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(400px, 1fr) );
  grid-column-gap: 24px;
  grid-row-gap: 64px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.video-wrapper iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-block h4 {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14pt;
  margin-bottom: 16px;
}




/********************************    MEDIA QUERIES    ********************************/


@media only screen and (orientation: portrait) {

  .home-intro {
    padding: 0 5%;
  }

  .menu-item {
    margin-bottom: 96px;
  }

  .menu-title {
    font-size: 48pt;
    margin-top: 16px;
    white-space: normal;
  }

  .menu-item:hover .menu-title {
    transform: scale(1.2);
    letter-spacing: normal;
  }

  .derek-photo {
    max-width: 100%;
    float: none;
    margin: 0 0 32px;
  }

  .three-video-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .four-image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin-top: 24px;
  }

  .the-boys {
    grid-template-columns: 1fr;
  }

  .learnings {
    grid-template-columns: 1fr 1fr;
  }

  .sub-section {
    margin-top: 96px;
  }

}

@media only screen and (orientation: portrait) and (max-width: 600px) {

  .home-intro h2 {
    font-size: 28pt;
  }
  
  .col-2-1 {
    grid-template-columns: 1fr;
  }

  .home-intro p {
    font-size: 18pt;
  }

  .tool-list h4 {
    font-size: 14pt;
  }

  .menu-item {
    margin-bottom: 64px;
  }

  .menu-title {
    font-size: 32pt;
  }

  .prototype-wrapper {
    margin-top: 24px;
  }

  .fifty-fifty {
    grid-template-columns: 1fr;
  }

  .paper-info {
    grid-column-start: 1;
  }

  .paper-image {
    width: 100%;
    grid-column-start: 1;
    grid-row-start: 2;
    margin-top: 24px;
  }

  .first-things {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-top: 0;
  }
  
  .iphone8-iframe-wrapper {
    margin-top: 24px;
  }  

  .three-video-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .learnings {
    grid-template-columns: 1fr;
    padding-right: 48px;
  }

  .section-subhead {
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
  }

  .double-wide {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .roadmap-wrapper {
    margin-top: 24px;
  }

  .project-nav-next h3 {
    font-size: 24pt;
  }
  
  .next-project {
      font-family: titling-gothic-fb-extended, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16pt;
  }

  .video-gallery {
    padding: 64px 5%;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  }

}


@media only screen and (max-width: 1020px) {
 
  .vertical-gallery > div {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }

  .menu-title {
    white-space: unset;
  }

}

@media only screen and (max-width: 800px) {
  .vertical-gallery > div {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 600px) {
  .vertical-gallery > div {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 14pt;
  }

}


@media only screen and (max-width: 1000px) {
  
  .menu-item {
    padding: 0 10%;
  }

  .project-info {
    display: flex;
    flex-direction: column;
    padding: 0 5% 64px;
  }

  .section-info {
    display: flex;
    flex-direction: column;
  }

  .horizontal-gallery {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    grid-gap: 16px;
  }

  .horizontal-gallery-block {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .section-centered {
    width: 100%;
  }
}


@media only screen and (max-width: 800px) {
  
  .work-link {
    margin-right: 24px;
  }
  
  section {
    padding: 128px 5% 24px;
  }

  .start-from-scratch .section-info  {
    padding: 0 5%;
  }

  .start-from-scratch h5 {
    padding: 0 5%;
  }

  header {
    padding: 24px 5%;
  }

  header.smaller {
    padding: 16px 5%;
  }

  .section-info {
    margin-bottom: 24px;
  }

  .project-intro {
    margin-bottom: 24px;
  }

  .project-description {
    font-size: 14pt;
  }

  /* .section-title {
    width: 80%;
  } */
}